import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    lang: string;
    copyYear: number;

    constructor(private translateService: TranslateService) {
    }

    ngOnInit(): void {
        this.lang = this.translateService.currentLang;

        this.copyYear = new Date().getFullYear();

        ((w, d) => {
            const loader = () => {
                const s = d.createElement('script');
                const tag = d.getElementsByTagName('script')[0];
                s.src = 'https://cdn.iubenda.com/iubenda.js';
                tag.parentNode.insertBefore(s, tag);
            };

            if (w.addEventListener) {
                w.addEventListener('load', loader, false);
            } else {
                w.onload = loader;
            }
        })(window, document);
    }

}
