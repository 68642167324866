import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    constructor(private translateService: TranslateService) {
    }

    init(locale: string = 'en'): void {
        this.translateService.addLangs(['en', 'nl', 'fr']);
        this.translateService.use(locale);
    }
}
