import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    languages: {
        code: string;
        name: string;
    }[];

    selectedLanguage: {
        code: string;
        name: string;
    };

    constructor(
        private ts: TranslateService
    ) {
        this.languages = [
            { code: 'en', name: 'EN' },
            { code: 'nl', name: 'NL'},
            { code: 'fr', name: 'FR' }
        ];
    }

    ngOnInit(): void {
    }

    onLanguageChange(e): void {
        this.ts.use(e.value.code);
    }

}
