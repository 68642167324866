import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslationService } from '../services/translation.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RouterModule } from '@angular/router';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';

export function translationInitializer(translationService: TranslationService): () => void {
    return () => {
        return translationService.init('en');
    };
}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        ScrollTopComponent
    ],
    imports: [
        CommonModule,
        DropdownModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        TranslateModule.forRoot({
            defaultLanguage: 'en',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        CommonModule,
        TranslateModule,
        ScrollTopComponent
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: translationInitializer,
            deps: [TranslationService],
            multi: true
        }
    ]
})
export class SharedModule {
}
