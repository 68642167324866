<nav class="navbar navbar-expand-lg navbar-light bg-transparent navbar-fixed-top pl-0 pr-0">
    <div class="container">
        <div class="d-flex flex-grow-1">
            <div class="mr-3">
                <button class="navbar-toggler border-0" type="button" data-toggle="collapse" data-target="#aexnaNavbar">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <a class="navbar-brand" [routerLink]="['/']">
                <img src="../../../assets/logo.png" alt="AEXNA BV">
            </a>
        </div>
        <div class="collapse navbar-collapse flex-grow-1" id="aexnaNavbar">
            <ul class="navbar-nav ml-auto flex-nowrap">
                <li class="nav-item mr-lg-4 pl-2 pl-lg-0">
                    <a [routerLink]="['/']" fragment="services" class="nav-link">{{ 'menu.services' | translate }}</a>
                </li>
                <li class="nav-item mr-lg-4 pl-2 pl-lg-0">
                    <a [routerLink]="['/']" fragment="contact"  class="nav-link">{{ 'menu.contact' | translate }}</a>
                </li>
                <li class="nav-item">
                    <p-dropdown [options]="languages" [(ngModel)]="selectedLanguage" optionLabel="name"
                                styleClass="language-dropdown" (onChange)="onLanguageChange($event)"></p-dropdown>
                </li>
            </ul>
        </div>
    </div>
</nav>
